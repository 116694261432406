import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const PinesImage = () => (
  <StaticImage
    alt="Pine trees"
    loading="eager"
    src="../images/pines.png"
    quality={90}
    style={{
      height: "100vh",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      zIndex: -1,
    }}
  />
)

export default PinesImage
