import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import CssBaseline from "@mui/material/CssBaseline"
import Typography from "@mui/material/Typography"
import { Link } from "gatsby-theme-material-ui"
import React from "react"
import Layout from "../components/Layout"
import PinesImage from "../components/PinesImage"
import SEO from "../components/SEO"

const IndexPage = () => (
  <>
    <CssBaseline />
    <Layout>
      <SEO title="Home" />
      <PinesImage />
      <Container
        maxWidth="md"
        style={{
          flexDirection: "column",
          width: "90%",
          textAlign: "center",
          margin: "0px auto",
          position: "absolute",
          left: 0,
          right: 0,
          top: "4rem",
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          marginBottom="1rem"
          variant="h1"
          style={{ textShadow: "1px 1px 2px black" }}
        >
          Spend some time in the pines
        </Typography>
        <Typography
          variant="subtitle1"
          marginBottom="1.5rem"
          style={{ textShadow: "1px 1px 2px black" }}
        >
          Join us 6,200 feet up in California's San Jacinto Mountains, just
          minutes from the beautiful mountain village of Idyllwild, and about a
          2-hour drive from most of Southern California. Enjoy spectacular views
          of Lily Rock, relax in our hot tub, listen to the rustling of the
          leaves, and watch the squirrels, blue jays, and woodpeckers play in
          the pines and oaks.
        </Typography>
        <Link to="/rooms" underline="none">
          <Button variant="contained" size="large">
            See Our Rooms
          </Button>
        </Link>
      </Container>
    </Layout>
  </>
)

export default IndexPage
